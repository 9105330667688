var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar" }, [
    _c(
      "div",
      { staticClass: "left" },
      [
        _c("hamburger", {
          staticClass: "hamburger-container",
          attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
          on: { toggleClick: _vm.toggleSideBar },
        }),
        _c("breadcrumb", {
          staticClass: "breadcrumb-container",
          attrs: { id: "breadcrumb-container" },
        }),
        _c(
          "div",
          { staticClass: "search-bar" },
          [
            _c("el-input", {
              attrs: {
                placeholder: "请输入帖文内容",
                "suffix-icon": "el-icon-search",
              },
              on: {
                change: function ($event) {
                  return _vm.searchTopic(_vm.search.keyword)
                },
              },
              model: {
                value: _vm.search.keyword,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "keyword", $$v)
                },
                expression: "search.keyword",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "right-menu" },
      [
        _vm.device !== "mobile"
          ? [
              _c(
                "span",
                { staticClass: "online-member" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "top-icon-member",
                      on: {
                        click: function ($event) {
                          _vm.showOnlineMember = !_vm.showOnlineMember
                          _vm.showArticle = false
                          _vm.showComment = false
                          _vm.showChoseAvatar = false
                        },
                      },
                    },
                    [
                      _c(
                        "el-badge",
                        {
                          staticClass: "article-badge",
                          attrs: { value: _vm.onlineMember, max: 99 },
                        },
                        [
                          _vm.showOnlineMember === true
                            ? _c("img", {
                                attrs: {
                                  src: require("@/assets/icon/navbar-member-active.svg"),
                                },
                              })
                            : _c("img", {
                                attrs: {
                                  src: require("@/assets/icon/navbar-member.svg"),
                                },
                              }),
                        ]
                      ),
                      _c("span", { staticClass: "member-tip" }, [
                        _vm._v("在线人数"),
                      ]),
                    ],
                    1
                  ),
                  _c("transition", { attrs: { name: "el-fade-in" } }, [
                    _vm.showOnlineMember
                      ? _c("div", { staticClass: "show-online-member" }, [
                          _vm.onlineMember
                            ? _c(
                                "div",
                                { staticClass: "haveData" },
                                _vm._l(
                                  _vm.onlineList.data,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "list" },
                                      [
                                        _c("div", { staticClass: "member" }, [
                                          _vm._v(_vm._s(item.account)),
                                        ]),
                                        _c("i", { staticClass: "time" }, [
                                          _vm._v(_vm._s(item.lastLoginat)),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _c("div", { staticClass: "noData" }, [
                                _c("div", [_vm._v("尚无资料")]),
                              ]),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c("screenfull", {
                staticClass: "right-menu-item hover-effect",
                staticStyle: { transform: "translateY(10%)" },
                attrs: { id: "screenfull" },
              }),
            ]
          : _vm._e(),
        _c(
          "a",
          {
            staticClass: "top-choose-img",
            on: {
              click: function ($event) {
                _vm.showChoseAvatar = !_vm.showChoseAvatar
                _vm.showOnlineMember = false
                _vm.showArticle = false
                _vm.showComment = false
              },
            },
          },
          [
            _vm.imgUrl
              ? _c("img", { attrs: { src: _vm.imgUrl, alt: "" } })
              : _vm.storeAvatar.includes("avatar")
              ? _c("img", {
                  attrs: { src: "" + _vm.s3Base + _vm.storeAvatar, alt: "" },
                })
              : _vm.storeAvatar.includes("http")
              ? _c("img", { attrs: { src: _vm.storeAvatar, alt: "" } })
              : _c("img", {
                  attrs: {
                    src: require("@/assets/site/" +
                      _vm.siteInfo.sitePrefix2 +
                      "/avatar/photo" +
                      _vm.storeAvatar +
                      ".png"),
                  },
                }),
          ]
        ),
        _c("transition", { attrs: { name: "el-fade-in" } }, [
          _vm.showChoseAvatar
            ? _c(
                "div",
                { staticClass: "avatar-list" },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-upload",
                      attrs: {
                        "show-file-list": false,
                        action: "#",
                        limit: 1,
                        "auto-upload": false,
                        accept:
                          ".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .GIF, .BMP",
                        "on-change": _vm.handleAvatarChange,
                      },
                    },
                    [
                      _vm.imgUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.imgUrl },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                  _vm._l(_vm.avatarList, function (avatar, index) {
                    return _c(
                      "a",
                      {
                        key: index,
                        class: { active: avatar === _vm.storeAvatar },
                        on: {
                          click: function ($event) {
                            return _vm.handlerSystemAvatar(avatar)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/site/" +
                              _vm.siteInfo.sitePrefix2 +
                              "/avatar/photo" +
                              avatar +
                              ".png"),
                          },
                        }),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ]),
        _c(
          "el-dropdown",
          {
            staticClass: "avatar-container right-menu-item hover-effect",
            staticStyle: { padding: "0 5px" },
            attrs: { trigger: "click" },
          },
          [
            _c("div", { staticClass: "avatar-wrapper" }, [
              _c("i", { staticClass: "el-icon-caret-bottom" }),
            ]),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              [
                _c("el-dropdown-item", [
                  _c(
                    "span",
                    {
                      staticStyle: { display: "block" },
                      on: { click: _vm.logout },
                    },
                    [_vm._v("登出")]
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }