/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var menberCenterRouter = {
  id: 159,
  path: '/memberCenter',
  component: Layout,
  redirect: 'noRedirect',
  name: 'memberCenter',
  meta: {
    title: '会员管理',
    icon: 'members'
  },
  children: [{
    id: 160,
    path: 'onlineMember',
    component: function component() {
      return import('@/views/memberCenter/onlineMember');
    },
    name: 'onlineMember',
    meta: {
      title: '在线会员',
      noCache: true
    }
  }, {
    id: 161,
    path: 'memberList',
    component: function component() {
      return import('@/views/memberCenter/memberList');
    },
    name: 'memberList',
    meta: {
      title: '会员列表 ',
      noCache: true
    }
  }]
};
export default menberCenterRouter;