/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var drawnRouter = {
  id: 152,
  path: '/liveDraw',
  component: Layout,
  redirect: 'noRedirect',
  name: 'liveDraw',
  meta: {
    title: '六合彩开奖',
    icon: 'liveDraw'
  },
  children: [{
    id: 153,
    path: 'management',
    component: function component() {
      return import('@/views/liveDraw/management');
    },
    name: 'liveDraw-management',
    meta: {
      title: '即时开奖',
      noCache: true
    }
  }, {
    id: 154,
    path: 'hk6VideoManagement',
    component: function component() {
      return import('@/views/liveDraw/hk6VideoManagement');
    },
    name: 'liveDraw-hk6VideoManagement',
    meta: {
      title: '开奖视频管理',
      noCache: true
    }
  }, {
    id: 155,
    path: 'rewardsManagement',
    component: function component() {
      return import('@/views/liveDraw/rewardsManagement');
    },
    name: 'liveDraw-rewardsManagement',
    meta: {
      title: '投注派彩设置',
      noCache: true
    }
  }]
};
export default drawnRouter;