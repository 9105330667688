/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var galleryRouter = {
  id: 136,
  path: '/gallery',
  component: Layout,
  redirect: 'noRedirect',
  name: 'gallery',
  meta: {
    title: '六合图库',
    icon: 'picture'
  },
  children: [{
    id: 137,
    path: 'management',
    component: function component() {
      return import('@/views/gallery/management');
    },
    name: 'management',
    meta: {
      title: '六合图库管理',
      noCache: true
    }
  }, {
    id: 138,
    path: 'commentManagement',
    component: function component() {
      return import('@/views/gallery/commentManagement');
    },
    name: 'gallery-commentManagement',
    meta: {
      title: '六合图库评论管理',
      noCache: true
    }
  }, {
    id: 1027,
    path: 'board-management',
    component: function component() {
      return import('@/views/gallery/board-management');
    },
    name: 'gallery-board-management',
    meta: {
      title: '六合图库看板管理',
      noCache: true
    }
  }]
};
export default galleryRouter;