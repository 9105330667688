var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view"),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.isShowResponseModal,
            top: "1%",
            width: "50%",
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShowResponseModal = $event
            },
            close: function ($event) {
              _vm.isShowResponseModal = false
            },
          },
        },
        [
          _c("div", { staticClass: "content" }, [
            _vm._v("操作过于频繁，请稍后再试"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", plain: "" },
              on: {
                click: function ($event) {
                  _vm.isShowResponseModal = false
                },
              },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }