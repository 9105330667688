/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var mysteryDataRouter = {
  id: 146,
  path: '/mysteryData',
  component: Layout,
  redirect: 'noRedirect',
  name: 'mysteryData',
  meta: {
    title: '玄机资料',
    icon: 'mystery'
  },
  children: [{
    id: 147,
    path: 'management',
    component: function component() {
      return import('@/views/mysteryData/management');
    },
    name: 'mysteryData-management',
    meta: {
      title: '玄机资料管理',
      noCache: true
    }
  }, {
    id: 148,
    path: 'commentManagement',
    component: function component() {
      return import('@/views/mysteryData/commentManagement');
    },
    name: 'mysteryData-commentManagement',
    meta: {
      title: '玄机资料评论管理',
      noCache: true
    }
  }]
};
export default mysteryDataRouter;