import axios from 'axios';
import { Message } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';
import moment from 'moment';
import ErrorCode from '@/api/errorCode';

// create an axios instance
var service = axios.create({
  baseURL: process.env.VUE_APP_SYSTEM_BASE,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent

  // 篩選日期的結束日期都 + 1 天
  if (config && config.data && config.data.endDate) {
    config.data.endDate = moment(config.data.endDate).add(1, 'd').hour(0).minute(0).second(0).format('YYYY/MM/DD');
  } else if (config && config.data && config.data.endTime) {
    // 發送紅包 需發送時間格式為:"yyyy-MM-dd HH:mm:ss"
    if (config.url === '/envelope/create') {
      config.data.endTime = moment(config.data.endTime).add(1, 'd').hour(0).minute(0).second(0).format('YYYY-MM-DD HH:mm:ss');
    } else {
      config.data.endTime = moment(config.data.endTime).add(1, 'd').hour(0).minute(0).second(0).format('YYYY-MM-DD');
    }
  } else if (config && config.data && config.data.end) {
    config.data.end = moment(config.data.end).add(1, 'd').hour(0).minute(0).second(0).format('YYYY/MM/DD HH:mm:ss');
  }
  if (store.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    // config.headers['X-Token'] = getToken();
    config.headers['Authorization'] = getToken();
  }
  return config;
}, function (error) {
  // do something with request error
  // console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  // if the custom code is not 20000, it is judged as an error.
  if (res.code !== 200) {
    ErrorCode.httpErrorHandler(res);
    return Promise.reject({
      message: new Error(res.message).message,
      code: res.code
    });
  } else {
    return res;
  }
}, function (error) {
  // console.log('err' + error); // for debug
  Message({
    // message: error.message,
    message: '网络连线发生问题，请稍稍候再试',
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;