import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import memberRequest from '@/utils/memberRequest';
import forumRequest from '@/utils/forumRequest';
export function logout() {
  return memberRequest({
    url: '/member/logout',
    method: 'post'
  });
}
export function getOnlineMemberList(_ref) {
  var sysType = _ref.sysType,
    account = _ref.account,
    pageIndex = _ref.pageIndex,
    pageSize = _ref.pageSize;
  if (sysType === undefined) {
    sysType = '-1';
  } else {
    sysType = parseInt(sysType);
  }
  return memberRequest({
    url: '/member/user-info/online/page',
    method: 'post',
    data: {
      sysType: sysType,
      account: account,
      pageIndex: pageIndex,
      pageSize: pageSize
    }
  });
}
export function kickOnlineMember(_ref2) {
  var userId = _ref2.userId,
    account = _ref2.account,
    agendId = _ref2.agendId;
  return memberRequest({
    url: '/member/kick',
    method: 'post',
    data: {
      userId: userId,
      account: account,
      agendId: agendId
    }
  });
}
export function getMemberDataByAccount(_ref3) {
  var account = _ref3.account,
    nickName = _ref3.nickName,
    email = _ref3.email;
  return memberRequest({
    url: "/member/get/".concat(account, "/"),
    method: 'post',
    data: {
      account: account,
      nickName: nickName,
      email: email
    }
  });
}
export function postManager(_ref4) {
  var account = _ref4.account,
    name = _ref4.name,
    password = _ref4.password,
    nickName = _ref4.nickName,
    phone = _ref4.phone,
    birthday = _ref4.birthday,
    gender = _ref4.gender,
    remark = _ref4.remark,
    status = _ref4.status,
    superiorAccount = _ref4.superiorAccount;
  return memberRequest({
    url: "/member/create",
    method: 'post',
    data: {
      account: account,
      name: name,
      password: password,
      nickName: nickName,
      phone: phone,
      birthday: birthday,
      gender: gender,
      remark: remark,
      status: status,
      superiorAccount: superiorAccount
    }
  });
}
export function postSubManager(_ref5) {
  var account = _ref5.account,
    password = _ref5.password,
    nickName = _ref5.nickName,
    email = _ref5.email;
  return memberRequest({
    url: "/member/create",
    method: 'post',
    data: {
      account: account,
      password: password,
      nickName: nickName,
      email: email,
      agentType: 'agt2'
    }
  });
}
export function updateMemberDataByAccount(_ref6) {
  var account = _ref6.account,
    nickName = _ref6.nickName,
    email = _ref6.email,
    password = _ref6.password;
  return memberRequest({
    url: "/member/update/".concat(account),
    method: 'post',
    data: {
      account: account,
      nickName: nickName,
      email: email,
      password: password
    }
  });
}
export function updateMemberAvatarByAccount(_ref7) {
  var account = _ref7.account,
    system = _ref7.system,
    upload = _ref7.upload;
  var formData = new FormData();
  var avatarData = {
    system: system,
    upload: upload
  };
  Object.keys(avatarData).map(function (key) {
    if (avatarData[key] !== undefined) {
      formData.append("".concat(key), avatarData[key]);
    }
  });
  return memberRequest({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: "member/avatar/update/".concat(account),
    method: 'post',
    data: formData
  });
}
export function getMembeAvatarByAccount(_ref8) {
  var account = _ref8.account;
  return memberRequest({
    url: "/member/get/".concat(account, "/"),
    method: 'post',
    data: {
      account: account
    }
  });
}
export function updateMemberDataById(_ref9) {
  var id = _ref9.id,
    name = _ref9.name,
    password = _ref9.password,
    nickName = _ref9.nickName,
    phone = _ref9.phone,
    birthday = _ref9.birthday,
    gender = _ref9.gender,
    remark = _ref9.remark,
    status = _ref9.status,
    superiorAccount = _ref9.superiorAccount;
  var data = {
    name: name,
    nickName: nickName,
    phone: phone,
    birthday: birthday,
    gender: gender,
    remark: remark,
    superiorAccount: superiorAccount
  };
  if (password !== null) {
    data.password = password;
  }
  if (status !== null) {
    data.status = status;
  }
  return memberRequest({
    url: "/member/update/".concat(id),
    method: 'post',
    data: data
  });
}
export function deleteMemberDataByAccountId(_ref10) {
  var id = _ref10.id;
  return memberRequest({
    url: "/member/delete/".concat(id),
    method: 'post',
    data: {}
  });
}
// export function getForumMemberList({ id, ids, issueNo, profileFilter, profileType, profileData, pageIndex, pageSize, status, startDate, endDate }) {
export function getForumMemberList(_ref11) {
  var id = _ref11.id,
    ids = _ref11.ids,
    issueNo = _ref11.issueNo,
    type = _ref11.type,
    keyword = _ref11.keyword,
    profileType = _ref11.profileType,
    pageIndex = _ref11.pageIndex,
    pageSize = _ref11.pageSize,
    status = _ref11.status,
    startDate = _ref11.startDate,
    endDate = _ref11.endDate;
  if (type === undefined) {
    type = 'ALL';
  }
  // if (profileType === undefined) {
  //   profileType = 'user';
  // }
  return forumRequest({
    url: '/manage/user/list',
    method: 'post',
    // data: { id, ids, issueNo, profileFilter, profileType, profileData, pageIndex, pageSize, status, startDate, endDate }
    data: {
      id: id,
      ids: ids,
      issueNo: issueNo,
      type: type,
      keyword: keyword,
      profileType: profileType,
      pageIndex: pageIndex,
      pageSize: pageSize,
      status: status,
      startDate: startDate,
      endDate: endDate
    }
  });
}

// export function getForumMemberStatic({ id, ids, issueNo, profileFilter, profilerData, pageIndex, pageSize, startDate, endDate }) {
//   return forumRequest({
//     url: '/manage/statistic/get',
//     method: 'post',
//     data: { id, ids, issueNo, profileFilter, profilerData, pageIndex, pageSize, startDate, endDate }
//   });
// }

export function updateForumMemberDataById(_ref12) {
  var profileId = _ref12.profileId,
    title = _ref12.title,
    totalFollows = _ref12.totalFollows;
  return forumRequest({
    url: '/manage/profile/update',
    method: 'post',
    data: {
      profileId: profileId,
      title: title,
      totalFollows: totalFollows
    }
  });
}

/**
 * 用户每日图表
 */
// export function getMemberChart({
//   profileId
// }) {
//   return forumRequest({
//     url: '/manage/statistic/getChartProfile',
//     method: 'post',
//     data: {
//       profileId
//     }
//   });
// }

/**
 * 取得圖形驗證資訊
 */
export function getVerification(_ref13) {
  var authCodeKey = _ref13.authCodeKey;
  return memberRequest({
    url: '/member/img-verification/get',
    method: 'post',
    data: {
      authCodeKey: authCodeKey
    }
  });
}
/**
 * 檢查Google驗證
 */
export function checkVerification(_ref14) {
  var authCode = _ref14.authCode;
  return memberRequest({
    url: '/member/google-authenticator/check',
    method: 'post',
    data: {
      authCode: authCode
    }
  });
}