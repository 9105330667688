import _objectSpread from "/var/lib/jenkins/workspace/v4-frontend-forum-backend/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, mapState('system', {
    siteInfo: function siteInfo(state) {
      return state.siteInfo;
    }
  }))
};