/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var activityManageRouter = {
  id: 168,
  path: '/activityManage',
  component: Layout,
  redirect: 'noRedirect',
  name: 'activityManage',
  meta: {
    title: '活动管理',
    icon: 'balloons',
    display: false
  },
  children: [{
    id: 169,
    path: 'activitySetting',
    component: function component() {
      return import('@/views/activityManage/activitySetting');
    },
    name: 'activitySetting',
    meta: {
      title: '活动设定',
      noCache: true
    }
  }, {
    id: 170,
    path: 'redEnvelope',
    component: function component() {
      return import('@/views/activityManage/redEnvelope');
    },
    name: 'redEnvelope',
    meta: {
      title: '红包',
      noCache: true
    }
  }, {
    id: 171,
    path: 'giveMoney',
    component: function component() {
      return import('@/views/activityManage/giveMoney');
    },
    name: 'giveMoney',
    meta: {
      title: '赠送彩金',
      noCache: true
    }
  }, {
    id: 172,
    path: 'activityList',
    component: function component() {
      return import('@/views/activityManage/activityList');
    },
    name: 'activityList',
    meta: {
      title: '活动资金明细',
      noCache: true
    }
  }]
};
export default activityManageRouter;