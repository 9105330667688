import _slicedToArray from "/var/lib/jenkins/workspace/v4-frontend-forum-backend/node_modules/@babel/runtime-corejs2/helpers/esm/slicedToArray.js";
import "core-js/modules/es7.object.entries";
import "core-js/modules/web.dom.iterable";
import Vue from 'vue';
var state = {
  count: 0,
  online: {},
  numberOfOnline: 0,
  reviewsList: {},
  topicList: {},
  reviews: {},
  getFirstData: false
};
var mutations = {
  SET_REVIEWS: function SET_REVIEWS(state, _ref) {
    var category = _ref.category,
      type = _ref.type,
      value = _ref.value;
    state.reviews[category][type.toUpperCase()] = value;
    state.count++;
  },
  SET_REPLY: function SET_REPLY(state, _ref2) {
    var data = _ref2.data;
    state.reviewsList = data;
  },
  SET_TOPIC: function SET_TOPIC(state, _ref3) {
    var data = _ref3.data;
    state.topicList = data;
  },
  SET_DATA: function SET_DATA(state, data) {
    state.getFirstData = true;
    Object.entries(data).forEach(function (entry) {
      var _entry = _slicedToArray(entry, 2),
        key = _entry[0],
        val = _entry[1];
      Vue.set(state.reviews, key, val);
    });
  },
  SET_ONLINE: function SET_ONLINE(state, data) {
    state.online = data;
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations
};