/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var chatroomManage = {
  id: 154,
  path: '/chatroomManage',
  component: Layout,
  redirect: 'noRedirect',
  name: 'chatroomManage',
  meta: {
    title: '聊天室管理',
    icon: 'chatbubbles'
  },
  children: [{
    id: 155,
    path: 'announce',
    component: function component() {
      return import('@/views/chatroomManage/announce');
    },
    name: 'chatroomManage-announce',
    meta: {
      title: '公告管理',
      noCache: true
    }
  }, {
    id: 156,
    path: 'forbid',
    component: function component() {
      return import('@/views/chatroomManage/forbid');
    },
    name: 'forbid',
    meta: {
      title: '禁言管理',
      noCache: true
    }
  }, {
    id: 157,
    path: 'privilege',
    component: function component() {
      return import('@/views/chatroomManage/privilege');
    },
    name: 'privilege',
    meta: {
      title: '指定帐号管理',
      noCache: true
    }
  }, {
    id: 158,
    path: 'shortMessage',
    component: function component() {
      return import('@/views/chatroomManage/shortMessage');
    },
    name: 'shortMessage',
    meta: {
      title: '短语管理',
      noCache: true
    }
  }, {
    id: 159,
    path: 'parameterManagement',
    component: function component() {
      return import('@/views/chatroomManage/parameterManagement');
    },
    name: 'parameterManagement',
    meta: {
      title: '参数管理',
      noCache: true
    }
  }]
};
export default chatroomManage;