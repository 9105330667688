import "core-js/modules/es6.regexp.replace";
import "core-js/modules/web.dom.iterable";
import Vue from 'vue';
import Vuex from 'vuex';
import getters from "./getters";
Vue.use(Vuex);

// https://webpack.js.org/guides/dependency-management/#requirecontext
var modulesFiles = require.context('./modules', true, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
var modules = modulesFiles.keys().reduce(function (modules, modulePath) {
  // set './app.js' => 'app'
  var moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  var value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
var store = new Vuex.Store({
  modules: modules,
  getters: getters
});
export default store;