import Cookies from 'js-cookie';
var TokenKey = 'Admin-Token';
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  localStorage.removeItem('googleCheck');
  return Cookies.remove(TokenKey);
}
export function getAccount() {
  return Cookies.get('account');
}
export function setAccount(account) {
  return Cookies.set('account', account);
}
export function removeAccount() {
  return Cookies.remove('account');
}
export function getId() {
  return Cookies.get('id');
}
export function setId(id) {
  return Cookies.set('id', id);
}
export function removeId() {
  return Cookies.remove('id');
}