var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _c("transition", { attrs: { name: "sidebarLogoFade" } }, [
        _vm.collapse
          ? _c("span", { key: "collapse", staticClass: "sidebar-logo-link" }, [
              _c("img", {
                staticClass: "sidebar-logo",
                attrs: {
                  src: require("@/assets/logo/" +
                    _vm.siteInfo.sitePrefix +
                    "/logo-small.svg"),
                },
              }),
            ])
          : _c("span", { key: "expand", staticClass: "sidebar-logo-link" }, [
              _c("img", {
                staticClass: "sidebar-logo",
                attrs: {
                  src: require("@/assets/logo/" +
                    _vm.siteInfo.sitePrefix +
                    "/logo.svg"),
                },
              }),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }