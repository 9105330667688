import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import request from '@/utils/systemRequest';
export function getAllowAddress(_ref) {
  var pageIndex = _ref.pageIndex,
    pageSize = _ref.pageSize;
  return request({
    url: '/system/whitelist/page',
    method: 'post',
    data: {
      pageIndex: pageIndex,
      pageSize: pageSize
    }
  });
}
export function updateAllowAddress(_ref2) {
  var id = _ref2.id,
    ip = _ref2.ip,
    remark = _ref2.remark;
  return request({
    url: '/system/whitelist/update',
    method: 'post',
    data: {
      allowAddressId: id,
      allowAddress: ip,
      remark: remark
    }
  });
}
export function addAllowAddress(_ref3) {
  var allowAddress = _ref3.allowAddress,
    remark = _ref3.remark;
  return request({
    url: '/system/whitelist/add',
    method: 'post',
    data: {
      allowAddress: allowAddress,
      remark: remark
    }
  });
}
export function deleteAllowAddress(_ref4) {
  var allowAddressId = _ref4.allowAddressId;
  return request({
    url: '/system/whitelist/delete',
    method: 'post',
    data: {
      allowAddressId: allowAddressId
    }
  });
}
export function getSystemAdminPage(_ref5) {
  var pageSize = _ref5.pageSize,
    pageIndex = _ref5.pageIndex,
    condition = _ref5.condition,
    value = _ref5.value,
    start = _ref5.start,
    end = _ref5.end;
  return request({
    url: '/system/admin/page',
    method: 'post',
    data: {
      pageSize: pageSize,
      pageIndex: pageIndex,
      condition: condition,
      value: value,
      start: start,
      end: end
    }
  });
}
export function getSystemGroupList() {
  return request({
    url: '/system/group/all/all',
    method: 'post',
    data: {}
  });
}
export function getSystemConfig() {
  return request({
    url: '/system/config/get',
    method: 'post',
    data: {}
  });
}
export function getLiveStreamConfig(_ref6) {
  var gameCode = _ref6.gameCode;
  return request({
    url: '/backend/live-stream-config/get',
    method: 'post',
    data: {
      gameCode: gameCode
    }
  });
}
export function updateLiveStreamConfig(_ref7) {
  var gameCode = _ref7.gameCode,
    freeWatchTime = _ref7.freeWatchTime,
    chooseSource = _ref7.chooseSource,
    sourceSetting = _ref7.sourceSetting;
  return request({
    url: '/backend/live-stream-config/update',
    method: 'post',
    data: {
      gameCode: gameCode,
      freeWatchTime: freeWatchTime,
      chooseSource: chooseSource,
      sourceSetting: sourceSetting
    }
  });
}
export function systemConfigUpdate(_ref8) {
  var weeChatSwitch = _ref8.weeChatSwitch,
    chatRoomRequirePhone = _ref8.chatRoomRequirePhone,
    chatRoomRequireBankCard = _ref8.chatRoomRequireBankCard,
    envelopeRequirePhone = _ref8.envelopeRequirePhone,
    envelopeRequireBankCard = _ref8.envelopeRequireBankCard,
    envelopeMax = _ref8.envelopeMax,
    envelopeAmount = _ref8.envelopeAmount,
    envelopeFee = _ref8.envelopeFee,
    liveWatchSeconds = _ref8.liveWatchSeconds,
    liveStreamSource = _ref8.liveStreamSource,
    liveStream = _ref8.liveStream;
  var inputData = {
    weeChatSwitch: weeChatSwitch,
    chatRoomRequirePhone: chatRoomRequirePhone,
    chatRoomRequireBankCard: chatRoomRequireBankCard,
    envelopeRequirePhone: envelopeRequirePhone,
    envelopeRequireBankCard: envelopeRequireBankCard,
    envelopeMax: envelopeMax,
    envelopeAmount: envelopeAmount,
    envelopeFee: envelopeFee,
    liveWatchSeconds: liveWatchSeconds,
    liveStreamSource: liveStreamSource,
    liveStream: liveStream
  };
  var formData = new FormData();
  Object.keys(inputData).map(function (key) {
    if (inputData[key] !== undefined) {
      formData.append("".concat(key), inputData[key]);
    }
  });
  // if (weeChatSwitch) {
  //   formData.append('weeChatSwitch', weeChatSwitch);
  // } else if (chatRoomRequirePhone) {
  //   formData.append('chatRoomRequirePhone', chatRoomRequirePhone);
  // } else if (chatRoomRequireBankCard) {
  //   formData.append('chatRoomRequireBankCard', chatRoomRequireBankCard);
  // } else if (envelopeRequirePhone) {
  //   formData.append('envelopeRequirePhone', envelopeRequirePhone);
  // } else if (envelopeRequireBankCard) {
  //   formData.append('envelopeRequireBankCard', envelopeRequireBankCard);
  // } else if (envelopeMax) {
  //   formData.append('envelopeMax', envelopeMax);
  // } else if (envelopeAmount) {
  //   formData.append('envelopeAmount', envelopeAmount);
  // } else if (envelopeFee) {
  //   formData.append('envelopeFee', envelopeFee);
  // } else if (liveWatchSeconds) { formData.append('liveWatchSeconds', liveWatchSeconds); }
  return request({
    url: "/system/config/update",
    method: 'post',
    data: formData
  });
}
export function addSystemMenu(_ref9) {
  var menuUrl = _ref9.menuUrl,
    menuName = _ref9.menuName,
    ordering = _ref9.ordering,
    parentId = _ref9.parentId,
    enabled = _ref9.enabled,
    backendType = _ref9.backendType,
    agentType = _ref9.agentType,
    iconClass = _ref9.iconClass;
  return request({
    url: '/system/menus/add',
    method: 'post',
    data: {
      menuUrl: menuUrl,
      menuName: menuName,
      ordering: ordering,
      parentId: parentId,
      enabled: enabled,
      backendType: backendType,
      agentType: agentType,
      iconClass: iconClass
    }
  });
}
export function updateSystemMenus(_ref10) {
  var menuId = _ref10.menuId,
    menuUrl = _ref10.menuUrl,
    menuName = _ref10.menuName,
    ordering = _ref10.ordering,
    parentId = _ref10.parentId,
    enabled = _ref10.enabled,
    backendType = _ref10.backendType,
    agentType = _ref10.agentType,
    iconClass = _ref10.iconClass;
  return request({
    url: '/system/menus/update',
    method: 'post',
    data: {
      menuId: menuId,
      menuUrl: menuUrl,
      menuName: menuName,
      ordering: ordering,
      parentId: parentId,
      enabled: enabled,
      backendType: backendType,
      agentType: agentType,
      iconClass: iconClass
    }
  });
}
export function deleteSystemMenus(_ref11) {
  var menuId = _ref11.menuId;
  return request({
    url: "/system/menus/delete/".concat(menuId),
    method: 'post',
    data: {
      menuId: menuId
    }
  });
}
export function getAllMenu(backendType) {
  return request({
    url: '/system/menus',
    method: 'post',
    data: {
      backendType: backendType
    }
  });
}
export function setAccountMenus(_ref12) {
  var id = _ref12.id,
    menuIds = _ref12.menuIds;
  return request({
    url: "/system/admin/menus/update/".concat(id),
    method: 'post',
    data: {
      id: id,
      menuIds: menuIds
    }
  });
}
export function googleSecret(id) {
  return request({
    url: "/system/admin/google-secret/".concat(id),
    method: 'post',
    data: {
      id: id
    }
  });
}
export function getSystemAdminMenus(id) {
  return request({
    url: "/system/admin/menus/get/".concat(id),
    method: 'post',
    data: {
      id: id
    }
  });
}

/**
 * echo
 */
export function echoServer() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "/server/echo",
    method: 'post',
    data: params
  });
}

/**
 * 取得聊天室白名单列表
 */
export function getChatRoomWhiteList(_ref13) {
  var chatroomId = _ref13.chatroomId;
  return request({
    url: "/api/chat-room/ban-white-list/get",
    method: 'post',
    data: {
      chatroomId: chatroomId
    }
  });
}

/**
 * 新增禁言白名單人員
 */
export function addWhiteList(_ref14) {
  var chatroomId = _ref14.chatroomId,
    userAccount = _ref14.userAccount;
  return request({
    url: "/api/chat-room/ban-white-list/add",
    method: 'post',
    data: {
      chatroomId: chatroomId,
      userAccount: userAccount
    }
  });
}

/**
 * 移除禁言白名單人員
 */
export function removeWhiteList(_ref15) {
  var chatroomId = _ref15.chatroomId,
    userAccount = _ref15.userAccount;
  return request({
    url: "/api/chat-room/ban-white-list/remove",
    method: 'post',
    data: {
      chatroomId: chatroomId,
      userAccount: userAccount
    }
  });
}

/**
 * 取得用戶微聊帳號
 */
export function getWeechatAccount(_ref16) {
  var userAccount = _ref16.userAccount;
  return request({
    url: "/api/chat-room/im-account/get",
    method: 'post',
    data: {
      userAccount: userAccount
    }
  });
}

/**
 * 登入融云
 */
export function loginIm(_ref17) {
  var isGuest = _ref17.isGuest;
  return request({
    url: "/api/im/login",
    method: 'post',
    data: {
      isGuest: isGuest
    }
  });
}

/**
 * 聊天室訊息撤回
 */
export function deleteMessage(_ref18) {
  var senderImAccount = _ref18.senderImAccount,
    chatRoomId = _ref18.chatRoomId,
    messageId = _ref18.messageId,
    sentTime = _ref18.sentTime;
  return request({
    url: "/api/chat-room/message/recall",
    method: 'post',
    data: {
      senderImAccount: senderImAccount,
      chatRoomId: chatRoomId,
      messageId: messageId,
      sentTime: sentTime
    }
  });
}

/**
 *即时通讯群组代理線設定查詢
 */
export function getImAgentGroupList() {
  return request({
    url: "/api/im/agent-group/list",
    method: 'post',
    data: {}
  });
}

/**
 * 即时通讯群组清單
 */
export function getImGroupList() {
  return request({
    url: "/api/im/group/list",
    method: 'post',
    data: {}
  });
}

/**
 * 即时通讯群组代理線設定刪除
 */
export function deleteAgentGroup(_ref19) {
  var id = _ref19.id;
  return request({
    url: "/api/im/agent-group/delete",
    method: 'post',
    data: {
      id: id
    }
  });
}

/**
 * 即时通讯群组代理線設定更新
 */
export function updateAgentGroup(_ref20) {
  var id = _ref20.id,
    account = _ref20.account,
    enable = _ref20.enable,
    imGroupData = _ref20.imGroupData;
  return request({
    url: "/api/im/agent-group/update",
    method: 'post',
    data: {
      id: id,
      account: account,
      enable: enable,
      imGroupData: imGroupData
    }
  });
}