/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var systemManageRouter = {
  id: 173,
  path: '/systemManage',
  component: Layout,
  redirect: 'noRedirect',
  name: 'systemManage',
  meta: {
    title: '系统管理',
    icon: 'configuration'
  },
  children: [{
    id: 174,
    path: 'subAccount',
    component: function component() {
      return import('@/views/systemManage/subAccount');
    },
    name: 'subAccount',
    meta: {
      title: '子帐号管理',
      noCache: true
    }
  }, {
    id: 175,
    path: 'whitelist',
    component: function component() {
      return import('@/views/systemManage/whitelist');
    },
    name: 'whitelist',
    meta: {
      title: 'IP白名单',
      noCache: true
    }
  }, {
    id: 176,
    path: 'multipleManage',
    component: function component() {
      return import('@/views/systemManage/multipleManage');
    },
    name: 'multipleManage',
    meta: {
      title: '倍率管理',
      noCache: true
    }
  }, {
    id: 177,
    path: 'shieldmanage',
    component: function component() {
      return import('@/views/systemManage/shieldmanage');
    },
    name: 'shieldmanage',
    meta: {
      title: '屏蔽管理',
      noCache: true
    }
  }, {
    id: 178,
    path: 'menuManage',
    component: function component() {
      return import('@/views/systemManage/menuManage');
    },
    name: 'menuManage',
    meta: {
      title: '选单管理',
      noCache: true
    }
  }, {
    id: 179,
    path: 'permission',
    component: function component() {
      return import('@/views/systemManage/permission');
    },
    name: 'permission',
    meta: {
      title: '绑定管理',
      noCache: true
    }
  }, {
    id: 180,
    path: 'auth',
    component: function component() {
      return import('@/views/systemManage/auth');
    },
    name: 'auth',
    meta: {
      title: '审核管理',
      noCache: true
    }
  }, {
    id: 181,
    path: 'vote',
    component: function component() {
      return import('@/views/systemManage/vote');
    },
    name: 'vote',
    meta: {
      title: '投票管理',
      noCache: true
    }
  }]
};
export default systemManageRouter;