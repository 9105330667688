import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.function.name";
import forumRequest from '@/utils/forumRequest';

/**
 * type => 論壇類型 article: 心水推薦, master: 六合大神, gallery: 六合圖庫
 * @param { category: 'article' | 'master' | 'gallery' }
 */
export function getForumCategory(_ref) {
  var category = _ref.category;
  return forumRequest({
    url: '/manage/forum/list',
    method: 'post',
    data: {
      category: category
    }
  });
}

/**
 * type => 論壇類型 article: 心水推薦, master: 六合大神, gallery: 六合圖庫
 * @param { category: 'article' | 'master' | 'gallery' }
 */
export function createForumCategory(_ref2) {
  var category = _ref2.category,
    name = _ref2.name;
  return forumRequest({
    url: '/manage/forum/create',
    method: 'post',
    data: {
      category: category,
      name: name
    }
  });
}

/**
 * category => 論壇類型 article: 心水推薦, master: 六合大神, gallery: 六合圖庫
 * @param { category: 'article' | 'master' | 'gallery' }
 * This API is Deprecated
 */
// export function getForumList({
//   category,
//   forumId,
//   forumIds,
//   pair,
//   issueNo,
//   profileFilter,
//   profileFilterData,
//   pageIndex,
//   pageSize,
//   status,
//   startDate,
//   endDate
// }) {
//   if (status === undefined) {
//     status = 'ALL';
//   }
//   return forumRequest({
//     url: '/manage/topic/' + category + '/list',
//     method: 'post',
//     data: {
//       id: forumId,
//       ids: forumIds,
//       pair,
//       issueNo,
//       profileFilter,
//       profileFilterData,
//       pageIndex,
//       pageSize,
//       status,
//       startDate,
//       endDate
//     }
//   });
// }

/**
 * category => 論壇類型 article: 心水推薦, master: 六合大神, gallery: 六合圖庫
 * @param { category: 'article' | 'master' | 'gallery' }
 */
export function getForumList(_ref3) {
  var category = _ref3.category,
    type = _ref3.type,
    keyword = _ref3.keyword,
    gameCode = _ref3.gameCode,
    startDate = _ref3.startDate,
    endDate = _ref3.endDate,
    pageIndex = _ref3.pageIndex,
    pageSize = _ref3.pageSize,
    filter = _ref3.filter;
  return forumRequest({
    url: '/manage/topic/list',
    method: 'post',
    data: {
      category: category,
      type: type,
      keyword: keyword,
      gameCode: gameCode,
      startDate: startDate,
      endDate: endDate,
      pageIndex: pageIndex,
      pageSize: pageSize,
      filter: filter
    }
  });
}

/**
 * category => 論壇類型 article: 心水推薦, master: 六合大神, gallery: 六合圖庫
 * @param { category: 'article' | 'master' | 'gallery' }
 */
export function getForumContent(_ref4) {
  var topicId = _ref4.topicId,
    category = _ref4.category,
    gameCode = _ref4.gameCode,
    colour = _ref4.colour;
  return forumRequest({
    url: '/manage/topic/read',
    method: 'post',
    data: {
      topicId: topicId,
      category: category,
      gameCode: gameCode,
      colour: colour
    }
  });
}

/**
 */
export function updateForumStatus(_ref5) {
  var topicId = _ref5.topicId,
    category = _ref5.category,
    status = _ref5.status,
    gameCode = _ref5.gameCode,
    ids = _ref5.ids;
  return forumRequest({
    url: '/manage/topic/state',
    method: 'post',
    data: {
      topicId: topicId,
      category: category,
      status: status,
      gameCode: gameCode,
      ids: ids
    }
  });
}

/**
 * 排序文章
 */
export function sortForumTopic(_ref6) {
  var category = _ref6.category,
    filter = _ref6.filter,
    sort = _ref6.sort,
    gameCode = _ref6.gameCode;
  return forumRequest({
    url: '/manage/topic/sort',
    method: 'post',
    data: {
      category: category,
      filter: filter,
      sort: sort,
      gameCode: gameCode
    }
  });
}

/**
 * 新增文章
 */
export function createForumArticle(_ref7) {
  var category = _ref7.category,
    forumId = _ref7.forumId,
    title = _ref7.title,
    issueNo = _ref7.issueNo,
    preview = _ref7.preview,
    content = _ref7.content,
    totalViews = _ref7.totalViews,
    totalLikes = _ref7.totalLikes,
    needReply = _ref7.needReply,
    hot = _ref7.hot,
    essential = _ref7.essential,
    headline = _ref7.headline,
    writerAccount = _ref7.writerAccount,
    gameCode = _ref7.gameCode;
  return forumRequest({
    url: '/manage/topic/create',
    method: 'post',
    data: {
      category: category,
      forumId: forumId,
      issueNo: issueNo,
      title: title,
      preview: preview,
      content: content,
      totalViews: totalViews,
      totalLikes: totalLikes,
      needReply: needReply,
      hot: hot,
      essential: essential,
      headline: headline,
      writerAccount: writerAccount,
      gameCode: gameCode
    }
  });
}

/**
 * 取得論壇設定
 */
export function getForumConfiguration() {
  return forumRequest({
    url: '/configuration/get',
    method: 'post',
    data: {}
  });
}

/**
 * 用户最新一篇文章
 * @param {String} writerAccount - 發文帳號
 */
export function latestTopic(_ref8) {
  var writerAccount = _ref8.writerAccount,
    gameCode = _ref8.gameCode,
    category = _ref8.category;
  return forumRequest({
    url: '/manage/topic/profile/latest',
    method: 'post',
    data: {
      writerAccount: writerAccount,
      gameCode: gameCode,
      category: category
    }
  });
}
export function getCategory(category) {
  return forumRequest({
    url: '/forum/list',
    method: 'post',
    data: {
      category: category
    }
  });
}
/**
 */
export function updateForumArticle(_ref9) {
  var category = _ref9.category,
    topicId = _ref9.topicId,
    title = _ref9.title,
    preview = _ref9.preview,
    review = _ref9.review,
    content = _ref9.content,
    totalViews = _ref9.totalViews,
    totalLikes = _ref9.totalLikes,
    needReply = _ref9.needReply,
    hot = _ref9.hot,
    essential = _ref9.essential,
    gameCode = _ref9.gameCode,
    issueNo = _ref9.issueNo;
  return forumRequest({
    url: '/manage/topic/update',
    method: 'post',
    data: {
      category: category,
      topicId: topicId,
      title: title,
      preview: preview,
      review: review,
      content: content,
      totalViews: totalViews,
      totalLikes: totalLikes,
      needReply: needReply,
      hot: hot,
      essential: essential,
      gameCode: gameCode,
      issueNo: issueNo
    }
  });
}

/**
 * category => 論壇類型 article: 心水推薦, master: 六合大神, gallery: 六合圖庫
 * @param { category: 'article' | 'master' | 'gallery' }
 * * This API is Deprecated
 */
// export function getForumArticleReplyList({
//   category,
//   id,
//   ids,
//   keyword,
//   year,
//   pair,
//   issueNo,
//   profileFilter,
//   profileType,
//   profileData,
//   pageIndex,
//   pageSize,
//   status,
//   startDate,
//   endDate
// }) {
//   return forumRequest({
//     url: '/manage/reply/' + category + '/list',
//     method: 'post',
//     data: {
//       id,
//       ids,
//       keyword,
//       year,
//       pair,
//       issueNo,
//       profileFilter,
//       profileType,
//       profileData,
//       pageIndex,
//       pageSize,
//       status,
//       startDate,
//       endDate
//     }
//   });
// }

/**
 * category => 論壇類型 article: 心水推薦, master: 六合大神, gallery: 六合圖庫
 * @param { category: 'article' | 'master' | 'gallery' }
 * * This API is Deprecated
 */
export function getForumArticleReplyList(_ref10) {
  var category = _ref10.category,
    replyId = _ref10.replyId,
    type = _ref10.type,
    keyword = _ref10.keyword,
    startDate = _ref10.startDate,
    endDate = _ref10.endDate,
    pageIndex = _ref10.pageIndex,
    pageSize = _ref10.pageSize,
    filter = _ref10.filter,
    gameCode = _ref10.gameCode,
    colour = _ref10.colour;
  return forumRequest({
    url: '/manage/reply/list',
    method: 'post',
    data: {
      category: category,
      replyId: replyId,
      type: type,
      keyword: keyword,
      startDate: startDate,
      endDate: endDate,
      pageIndex: pageIndex,
      pageSize: pageSize,
      filter: filter,
      gameCode: gameCode,
      colour: colour
    }
  });
}

/**
 */
export function updateForumArticleReplyStatus(_ref11) {
  var replyId = _ref11.replyId,
    category = _ref11.category,
    status = _ref11.status,
    gameCode = _ref11.gameCode,
    ids = _ref11.ids,
    colour = _ref11.colour;
  return forumRequest({
    url: '/manage/reply/state',
    method: 'post',
    data: {
      replyId: replyId,
      category: category,
      status: status,
      gameCode: gameCode,
      ids: ids,
      colour: colour
    }
  });
}

/**
 */
export function updateForumArticleReply(_ref12) {
  var category = _ref12.category,
    content = _ref12.content,
    replyId = _ref12.replyId,
    totalViews = _ref12.totalViews,
    totalLikes = _ref12.totalLikes,
    gameCode = _ref12.gameCode,
    colour = _ref12.colour;
  return forumRequest({
    url: '/manage/reply/update',
    method: 'post',
    data: {
      category: category,
      content: content,
      replyId: replyId,
      totalViews: totalViews,
      totalLikes: totalLikes,
      gameCode: gameCode,
      colour: colour
    }
  });
}

/**
 */
// export function uploadForumGallery({ forumId, issueNo, file }) {
//   const formData = new FormData();
//   file.forEach((item, index) => {
//     if (!issueNo.isArray) {
//       formData.append('issueNo', issueNo);
//     } else {
//       formData.append('issueNo', issueNo[index]);
//     }
//     formData.append('file', item.raw);
//   });

//   return forumRequest({
//     url: `/manage/gallery/${forumId}/upload`,
//     method: 'post',
//     data: formData
//   });
// }
export function uploadForumGallery(_ref13) {
  var category = _ref13.category,
    forumId = _ref13.forumId,
    issueNo = _ref13.issueNo,
    file = _ref13.file;
  var formData = new FormData();
  formData.append('forumId', forumId);
  formData.append('category', category);
  file.forEach(function (item) {
    formData.append('issueNo', issueNo);
    formData.append('file', item.raw);
  });
  return forumRequest({
    url: '/manage/crawler/upload',
    method: 'post',
    data: formData
  });
}

/**
 * 取得熱搜推薦
 */
export function getKeyword() {
  return forumRequest({
    url: '/manage/keyword/recommend/get',
    method: 'post',
    data: {}
  });
}

/**
 * 新增熱搜推薦
 * @param {String} - content
 */
export function addKeyword(_ref14) {
  var content = _ref14.content;
  return forumRequest({
    url: '/manage/keyword/recommend/create',
    method: 'post',
    data: {
      content: content
    }
  });
}

/**
 * 刪除熱搜推薦
 * @param {String} - content
 */
export function delKeyword(_ref15) {
  var content = _ref15.content;
  return forumRequest({
    url: '/manage/keyword/recommend/delete',
    method: 'post',
    data: {
      content: content
    }
  });
}

/**
 * 排序熱搜推薦
 * @param {Array} - content
 */
export function sortKeyword(_ref16) {
  var content = _ref16.content,
    index = _ref16.index;
  return forumRequest({
    url: '/manage/keyword/recommend/sort',
    method: 'post',
    data: {
      content: content,
      index: index
    }
  });
}

/**
 * 更新热搜推荐
 * @param {String} - original | content
 */
export function updateKeyword(_ref17) {
  var original = _ref17.original,
    content = _ref17.content;
  return forumRequest({
    url: '/manage/keyword/recommend/update',
    method: 'post',
    data: {
      original: original,
      content: content
    }
  });
}

/**
 * 设定回复可见
 */
export function needReply(_ref18) {
  var category = _ref18.category,
    topicId = _ref18.topicId,
    status = _ref18.status;
  return forumRequest({
    url: '/manage/topic/needReply',
    method: 'post',
    data: {
      category: category,
      topicId: topicId,
      status: status.toString()
    }
  });
}

// ---- 举报管理 ----

/**
 * 取得举报
 */
export function reportInfo() {
  return forumRequest({
    url: '/manage/complaint/reason/get',
    method: 'post',
    data: {}
  });
}

/**
 * 新增举报
 */
export function addReport(_ref19) {
  var content = _ref19.content;
  return forumRequest({
    url: '/manage/complaint/reason/create',
    method: 'post',
    data: {
      content: content
    }
  });
}

/**
 * 删除举报
 */
export function deleteReport(_ref20) {
  var content = _ref20.content;
  return forumRequest({
    url: '/manage/complaint/reason/delete',
    method: 'post',
    data: {
      content: content
    }
  });
}

/**
 * 更新举报
 */
export function updateReport(_ref21) {
  var original = _ref21.original,
    content = _ref21.content;
  return forumRequest({
    url: '/manage/complaint/reason/update',
    method: 'post',
    data: {
      original: original,
      content: content
    }
  });
}

/**
 * 排序举报
 */
export function sortReport(_ref22) {
  var content = _ref22.content,
    index = _ref22.index;
  return forumRequest({
    url: '/manage/complaint/reason/sort',
    method: 'post',
    data: {
      content: content,
      index: index
    }
  });
}

/**
 * 举报清单
 */
export function reportList(_ref23) {
  var type = _ref23.type,
    keyword = _ref23.keyword,
    startDate = _ref23.startDate,
    endDate = _ref23.endDate,
    pageIndex = _ref23.pageIndex,
    pageSize = _ref23.pageSize;
  return forumRequest({
    url: '/manage/complaint/list',
    method: 'post',
    data: {
      type: type,
      keyword: keyword,
      startDate: startDate,
      endDate: endDate,
      pageIndex: pageIndex,
      pageSize: pageSize
    }
  });
}

/**
 * 举报审核
 */
export function reportAudit(_ref24) {
  var complaintId = _ref24.complaintId,
    status = _ref24.status;
  return forumRequest({
    url: '/manage/complaint/state',
    method: 'post',
    data: {
      complaintId: complaintId,
      status: status
    }
  });
}

// ---- GIF管理 ----

/**
 * 群組(貼圖GIF)清單
 * @param { groupId }
 * 不帶, 則回傳群組清單
 * 有帶, 則回該群組貼圖清單
 */

export function gifList(groupId) {
  return forumRequest({
    url: '/manage/emoticon/list',
    method: 'post',
    data: {
      groupId: groupId
    }
  });
}
/**
 * 上傳GIF貼圖
 * 貼圖上傳時，預設為啟用狀態。
 * 但若啟用數量超過上限，則改為停用
 */
export function addGif(_ref25) {
  var groupId = _ref25.groupId,
    files = _ref25.files;
  var formData = new FormData();
  formData.append('groupId', groupId);
  files.forEach(function (file) {
    formData.append('files', file);
  });
  return forumRequest({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: '/manage/emoticon/upload',
    method: 'post',
    data: formData
  });
}

/**
 * 删除群組(貼圖GIF)
 * @param { groupId }
 * 有帶, 則為排序貼圖順序
 * 不帶, 則為排序群組順序
 */
export function deleteGroupGif(groupId) {
  return forumRequest({
    url: '/manage/emoticon/delete',
    method: 'post',
    data: {
      groupId: groupId
    }
  });
}
/**
 * 删除群組(貼圖GIF)
 * @param { emoticonId }
 */
export function deleteGif(emoticonId, groupId) {
  return forumRequest({
    url: '/manage/emoticon/delete',
    method: 'post',
    data: {
      emoticonId: emoticonId,
      groupId: groupId
    }
  });
}

/**
 * 排序貼圖GIF
 * @param { groupId }
 * 有帶, 則為刪除群組
 * 不帶, 則為刪除貼圖GIF
 */
export function sortGif(_ref26) {
  var groupId = _ref26.groupId,
    sort = _ref26.sort;
  return forumRequest({
    url: '/manage/emoticon/sort',
    method: 'post',
    data: {
      groupId: groupId,
      sort: sort
    }
  });
}
/**
 * 排序群組
 * @param { groupId }
 * 有帶, 則為刪除群組
 * 不帶, 則為刪除貼圖GIF
 */
export function sortGifGroup(_ref27) {
  var sort = _ref27.sort;
  return forumRequest({
    url: '/manage/emoticon/sort',
    method: 'post',
    data: {
      sort: sort
    }
  });
}
/**
 * 新增GIF群組
 * @param { name } 群組名稱
 */
export function addGifGroup(_ref28) {
  var name = _ref28.name;
  return forumRequest({
    url: '/manage/emoticon/create',
    method: 'post',
    data: {
      name: name
    }
  });
}
/**
 * 更新GIF群組
 * @param { name } 群組名稱p
 */
export function updateGifGroup(_ref29) {
  var groupId = _ref29.groupId,
    name = _ref29.name;
  return forumRequest({
    url: '/manage/emoticon/update',
    method: 'post',
    data: {
      groupId: groupId,
      name: name
    }
  });
}

/**
 * 取得自订资讯管理设定
 */
export function getConfiguration(_ref30) {
  var category = _ref30.category;
  return forumRequest({
    url: '/manage/configuration/get',
    method: 'post',
    data: {
      category: category
    }
  });
}

/**
 * 更新自订资讯管理设定
 */
export function updateConfiguration(_ref31) {
  var category = _ref31.category,
    gameCode = _ref31.gameCode,
    file = _ref31.file;
  var formData = new FormData();
  formData.append('category', category);
  formData.append('gameCode', gameCode);
  if (file) {
    formData.append('file', file.raw);
  }

  // file.forEach((item) => {
  //   formData.append('category', issueNo);
  //   formData.append('file', item.raw);
  // });

  return forumRequest({
    url: '/manage/configuration/upload',
    method: 'post',
    data: formData
  });
}

/**
 * 取得看板管理清單 資料大全/六合圖庫
 * @param {*} params
 * @returns
 */
export function getBoardManagement() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return forumRequest({
    url: '/manage/board/list',
    method: 'post',
    data: params
  });
}

/**
 * 取得看板管理清單
 * @param {*} params
 * @returns
 */
export function updateBoardState() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return forumRequest({
    url: '/manage/board/state',
    method: 'post',
    data: params
  });
}