/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var drawnRouter = {
  id: 153,
  path: '/drawn',
  component: Layout,
  redirect: 'noRedirect',
  name: 'drawn',
  meta: {
    icon: 'event',
    noCache: true
  },
  children: [{
    id: 153,
    path: 'drawnManage',
    component: function component() {
      return import('@/views/drawn/drawnManage');
    },
    name: 'drawn-drawnManage',
    meta: {
      title: '开奖日历',
      noCache: true
    }
  }]
};
export default drawnRouter;