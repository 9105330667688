/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var jockeyDataRouter = {
  id: 149,
  path: '/jockeyData',
  component: Layout,
  redirect: 'noRedirect',
  name: 'jockeyData',
  meta: {
    title: '马会资料',
    icon: 'jockey'
  },
  children: [{
    id: 150,
    path: 'management',
    component: function component() {
      return import('@/views/jockeyData/management');
    },
    name: 'jockeyData-management',
    meta: {
      title: '马会资料管理',
      noCache: true
    }
  }, {
    id: 151,
    path: 'commentManagement',
    component: function component() {
      return import('@/views/jockeyData/commentManagement');
    },
    name: 'jockeyData-commentManagement',
    meta: {
      title: '马会资料评论管理',
      noCache: true
    }
  }]
};
export default jockeyDataRouter;