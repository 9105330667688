import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import Vue from 'vue';
import { getSiteInfo } from '@/enum/siteEnum';
var initState = function initState() {
  return {
    siteId: window._jsvar_siteId,
    siteInfo: {},
    siteSystemInfo: {}
  };
};
var state = initState();
var mutations = {
  SET_SITE_ID: function SET_SITE_ID(state, value) {
    if (value) {
      state.siteId = value;
      state.siteInfo = getSiteInfo(value);
    }
  },
  SET_SYSTEM_CONFIG: function SET_SYSTEM_CONFIG(state, _ref) {
    var data = _ref.data,
      info = _ref.info;
    Object.keys(data).forEach(function (key) {
      if (key === 'siteId') return;
      Vue.set(state, key, data[key]);
    });
    if (info) {
      state.siteSystemInfo = info;
    }
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations
};