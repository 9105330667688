/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var announceRouter = {
  id: 162,
  path: '/announce',
  component: Layout,
  redirect: 'noRedirect',
  name: 'announce',
  meta: {
    title: '消息管理',
    icon: 'megaphone'
  },
  children: [{
    id: 163,
    path: 'systemAnnounce',
    component: function component() {
      return import('@/views/announce/systemAnnounce');
    },
    name: 'systemAnnounce',
    meta: {
      title: '消息中心公告',
      noCache: true
    }
  }, {
    id: 164,
    path: 'stationNews',
    component: function component() {
      return import('@/views/announce/stationNews');
    },
    name: 'stationNews',
    meta: {
      title: '站内信息',
      noCache: true
    }
  }, {
    id: 165,
    path: 'mainAnnounce',
    component: function component() {
      return import('@/views/announce/mainAnnounce');
    },
    name: 'mainAnnounce',
    meta: {
      title: '系统公告',
      noCache: true
    }
  }, {
    id: 166,
    path: 'marquee',
    component: function component() {
      return import('@/views/announce/marquee');
    },
    name: 'marquee',
    meta: {
      title: '跑马灯',
      noCache: true
    }
  }, {
    id: 167,
    path: 'recommendBet',
    component: function component() {
      return import('@/views/announce/recommendBet');
    },
    name: 'recommendBet',
    meta: {
      title: '推荐下注管理',
      noCache: true
    }
  }]
};
export default announceRouter;