/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var textDataRouter = {
  id: 143,
  path: '/textData',
  component: Layout,
  redirect: 'noRedirect',
  name: 'textData',
  meta: {
    title: '文字资料',
    icon: 'text'
  },
  children: [{
    id: 144,
    path: 'management',
    component: function component() {
      return import('@/views/textData/management');
    },
    name: 'textData-management',
    meta: {
      title: '文字资料管理',
      noCache: true
    }
  }, {
    id: 145,
    path: 'commentManagement',
    component: function component() {
      return import('@/views/textData/commentManagement');
    },
    name: 'textData-commentManagement',
    meta: {
      title: '文字资料评论管理',
      noCache: true
    }
  }]
};
export default textDataRouter;