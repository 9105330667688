import defaultSettings from '@/settings';
import { getSiteInfo } from '@/enum/siteEnum';
var site = getSiteInfo(window._jsvar.siteId);
var useSiteName = function useSiteName() {
  return site.siteId === 104 || site.siteId === 105;
};
var title = useSiteName() ? "".concat(site.siteName, "\u8BBA\u575B\u540E\u53F0") : defaultSettings.title || 'Vue Element Admin';
export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return "".concat(pageTitle, " - ").concat(title);
  }
  return "".concat(title);
}