/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var annualDataRouter = {
  id: 140,
  path: '/annualData',
  component: Layout,
  redirect: 'noRedirect',
  name: 'annualData',
  meta: {
    title: '全年资料',
    icon: 'annual'
  },
  children: [{
    id: 141,
    path: 'management',
    component: function component() {
      return import('@/views/annualData/management');
    },
    name: 'annual-management',
    meta: {
      title: '全年资料管理',
      noCache: true
    }
  }, {
    id: 142,
    path: 'commentManagement',
    component: function component() {
      return import('@/views/annualData/commentManagement');
    },
    name: 'management-commentManagement',
    meta: {
      title: '全年资料评论管理',
      noCache: true
    }
  }]
};
export default annualDataRouter;