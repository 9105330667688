import "core-js/modules/es6.function.name";
import _objectSpread from "/var/lib/jenkins/workspace/v4-frontend-forum-backend/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import path from 'path';
import { isExternal } from '@/utils/validate';
import Item from "./Item";
import AppLink from "./Link";
import FixiOSBug from "./FixiOSBug";
import { mapState } from 'vuex';
export default {
  name: 'SidebarItem',
  components: {
    Item: Item,
    AppLink: AppLink
  },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null;
    return {
      unReviews: 0,
      unReviewsCount: 0,
      total: 0
    };
  },
  computed: _objectSpread({}, mapState('unreviews', {
    reviews: function reviews(state) {
      return state.reviews;
    },
    count: function count(state) {
      return state.count;
    },
    getFirstData: function getFirstData(state) {
      return state.getFirstData;
    }
  })),
  watch: {
    'count': {
      handler: function handler(e) {
        this.updateSidebarCount();
      },
      deep: true,
      immediate: true
    },
    'getFirstData': {
      handler: function handler(e) {
        this.updateSidebarCount();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    hasOneShowingChild: function hasOneShowingChild() {
      var _this = this;
      var children = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var parent = arguments.length > 1 ? arguments[1] : undefined;
      var showingChildren = children.filter(function (item) {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          _this.onlyOneChild = item;
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = _objectSpread(_objectSpread({}, parent), {}, {
          path: '',
          noShowingChildren: true
        });
        return true;
      }
      return false;
    },
    resolvePath: function resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath;
      }
      if (isExternal(this.basePath)) {
        return this.basePath;
      }
      return path.resolve(this.basePath, routePath);
    },
    updateSidebarCount: function updateSidebarCount() {
      if (this.reviews.ARTICLE) {
        switch (this.item.name) {
          case 'recommend-postManage':
            this.unReviewsCount = this.reviews.ARTICLE.TOPIC.total;
            break;
          case 'recommend-commentManage':
            this.unReviewsCount = this.reviews.ARTICLE.REPLY.total;
            break;
          case 'recommend-headlineCommentManage':
            this.unReviewsCount = this.reviews.HEADLINE.REPLY.total;
            break;
          case 'gallery-management':
            this.unReviewsCount = this.reviews.GALLERY.TOPIC.total;
            break;
          case 'gallery-commentManagement':
            this.unReviewsCount = this.reviews.GALLERY.REPLY.total;
            break;
          case 'comment-management':
            this.unReviewsCount = this.reviews.ENCYCLOPEDIA.REPLY.total;
            break;
          case 'recommend':
            this.total = this.reviews.ARTICLE.TOPIC.total + this.reviews.ARTICLE.REPLY.total;
            break;
          case 'gallery':
            this.total = this.reviews.GALLERY.TOPIC.total + this.reviews.GALLERY.REPLY.total;
            break;
          case 'encyclopedia':
            this.total = this.reviews.ENCYCLOPEDIA.TOPIC.total + this.reviews.ENCYCLOPEDIA.REPLY.total;
            break;
          default:
            break;
        }
      }
    }
  }
};