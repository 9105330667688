import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/var/lib/jenkins/workspace/v4-frontend-forum-backend/node_modules/core-js/modules/es6.array.iterator.js";
import "/var/lib/jenkins/workspace/v4-frontend-forum-backend/node_modules/core-js/modules/es6.promise.js";
import "/var/lib/jenkins/workspace/v4-frontend-forum-backend/node_modules/core-js/modules/es6.object.assign.js";
import "/var/lib/jenkins/workspace/v4-frontend-forum-backend/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets

import Element from 'element-ui';
import "./styles/element-variables.scss";
import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import "./icons"; // icon
import "./permission"; // permission control
import "./analytics"; // import analytics plugin
import "./utils/error-log"; // error log

import * as filters from "./filters"; // global filters
import Axios from 'axios';
Vue.prototype.$axios = Axios;
import JwPagination from 'jw-vue-pagination';
Vue.component('jw-pagination', JwPagination);
Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
});

// Element dialog global config
Element.Dialog.props.closeOnClickModal.default = false;

// register global utility filters
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;

// set global store
window.$store = store;
window.$vue = new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});