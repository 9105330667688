// 根据后台返回的权限数组，筛选对应的路由信息对象
export function routerListFun(arr, allList) {
  var arrArray = [];
  for (var i = 0; i < arr.length; i++) {
    for (var k = 0; k < allList.length; k++) {
      if (arr[i] === allList[k].id) {
        arrArray.push(allList[k]);
      }
    }
  }
  for (var _i = 0; _i < arrArray.length; _i++) {
    if (arrArray[_i].children && arrArray[_i].children.length > 0) {
      arrArray[_i].childrens = [];
      for (var _k = 0; _k < arrArray[_i].children.length; _k++) {
        for (var j = 0; j < arr.length; j++) {
          if (arrArray[_i].children[_k].id === arr[j]) {
            arrArray[_i].childrens.push(arrArray[_i].children[_k]);
          }
        }
      }
      arrArray[_i].children = arrArray[_i].childrens;
    }
  }
  return arrArray;
}

// 根据全部的路由信息对象 返回 权限列表

export function routerListStr(routerStr) {
  var routerJson = JSON.parse(routerStr);
  for (var i = 0; i < routerJson.length; i++) {
    if (routerJson[i].component) {
      routerJson[i].component = '';
    }
    if (routerJson[i].children && routerJson[i].children.length > 0) {
      for (var k = 0; k < routerJson[i].children.length; k++) {
        routerJson[i].children[k].component = '';
      }
    }
  }
  return routerJson;
}