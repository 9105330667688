import memberRequest from '@/utils/memberRequest';
export function login(_ref) {
  var account = _ref.account,
    password = _ref.password,
    authCode = _ref.authCode,
    authCodeKey = _ref.authCodeKey;
  return memberRequest({
    url: '/member/admin/login',
    method: 'post',
    data: {
      account: account,
      password: password,
      authCode: authCode,
      authCodeKey: authCodeKey
    }
  });
}