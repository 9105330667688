/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var recommendRouter = {
  id: 133,
  path: '/recommend',
  component: Layout,
  redirect: 'noRedirect',
  name: 'recommend',
  meta: {
    title: '心水推荐',
    icon: 'writing'
  },
  children: [{
    id: 134,
    path: 'postManage',
    component: function component() {
      return import('@/views/recommend/postManage');
    },
    name: 'postManage',
    meta: {
      title: '心水帖文管理',
      noCache: true
    }
  }, {
    id: 135,
    path: 'commentManage',
    component: function component() {
      return import('@/views/recommend/commentManage');
    },
    name: 'recommend-commentManage',
    meta: {
      title: '心水评论管理',
      noCache: true
    }
  }, {
    id: 136,
    path: 'searchManage',
    component: function component() {
      return import('@/views/recommend/searchManage');
    },
    name: 'recommend-searchManage',
    meta: {
      title: '搜索管理',
      noCache: true
    }
  }, {
    id: 137,
    path: 'reportManage',
    component: function component() {
      return import('@/views/recommend/reportManage');
    },
    name: 'recommend-reportManage',
    meta: {
      title: '举报管理',
      noCache: true
    }
  }, {
    id: 138,
    path: 'reportHanding',
    component: function component() {
      return import('@/views/recommend/reportHanding');
    },
    name: 'recoomend-reportHanding',
    meta: {
      title: '举报处理',
      noCache: true
    }
  }]
};
export default recommendRouter;