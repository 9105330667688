import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/* Router Modules */
import liveDraw from "./modules/liveDraw";
import announce from "./modules/announce";
import gallery from "./modules/gallery";
import memberCenter from "./modules/memberCenter";
import recommend from "./modules/recommend";
import systemManage from "./modules/systemManage";
import activityManage from "./modules/activityManage";
import chatroomManage from "./modules/chatroomManage";
import annualData from "./modules/annualData";
import textData from "./modules/textData";
import mysteryData from "./modules/mysteryData";
import jockeyData from "./modules/jockeyData";
import drawn from "./modules/drawn";
export var constantRoutes = [{
  path: '/maintenance/403',
  component: function component() {
    return import('@/views/maintenance/403/index');
  },
  hidden: true
}, {
  path: '/maintenance/500',
  component: function component() {
    return import('@/views/maintenance/500/index');
  },
  hidden: true
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/auth-redirect');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/error-page/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/error-page/401');
  },
  hidden: true
}, {
  path: '/profile',
  component: Layout,
  redirect: '/profile/index',
  hidden: true,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/profile/index');
    },
    name: 'Profile',
    meta: {
      title: 'Profile',
      icon: 'user',
      noCache: true
    }
  }]
}];
export var asyncRoutes = [{
  id: 132,
  path: '/',
  component: Layout,
  redirect: '/memberCenter/onlineMember',
  children: [{
    id: 132,
    path: 'main',
    component: function component() {
      return import('@/views/main/index');
    },
    name: 'main',
    meta: {
      title: '数据报表',
      icon: 'home',
      affix: true
    }
  }]
}, recommend, gallery, annualData, textData, mysteryData, jockeyData, liveDraw, drawn, chatroomManage, memberCenter, announce, activityManage, systemManage, {
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    base: process.env.BASE_URL,
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;