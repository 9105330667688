import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import forumRequest from '@/utils/forumRequest';
import systemRequest from '@/utils/systemRequest';

/**
 * @param category required, ratio, review, threshold, image
 */
export function getManageForumSetting(_ref) {
  var category = _ref.category;
  return forumRequest({
    // url: `/manage/backend/${category}/get`,
    url: "/manage/configuration/get",
    method: 'post',
    data: {
      category: category
    }
  });
}
export function updateManageFourmSetting(_ref2) {
  var category = _ref2.category,
    data = _ref2.data,
    file = _ref2.file,
    field = _ref2.field,
    gameCode = _ref2.gameCode;
  var formData = new FormData();
  var forumData = {
    category: category,
    data: JSON.stringify(data),
    file: file,
    field: field,
    gameCode: gameCode
  };
  Object.keys(forumData).map(function (key) {
    if (forumData[key] !== undefined) {
      formData.append("".concat(key), forumData[key]);
    }
  });
  return forumRequest({
    url: "/manage/configuration/update",
    method: 'post',
    data: formData
  });
}
export function updateManageFourmImageSetting(_ref3) {
  var category = _ref3.category,
    field = _ref3.field,
    file = _ref3.file;
  var formData = new FormData();
  formData.append('category', category);
  formData.append('field', field);
  if (file) {
    formData.append('file', file);
  }
  return forumRequest({
    // url: `/manage/backend/${category}/upload`,
    url: "/manage/configuration/upload",
    method: 'post',
    data: formData
  });
}
export function updateVirtualVote(_ref4) {
  var vote = _ref4.vote,
    issueNo = _ref4.issueNo,
    gameCode = _ref4.gameCode;
  return forumRequest({
    url: "/manage/vote/update",
    method: 'post',
    data: {
      data: vote,
      issueNo: issueNo,
      gameCode: gameCode
    }
  });
}
export function getVote(_ref5) {
  var issueNo = _ref5.issueNo,
    gameCode = _ref5.gameCode;
  var data;
  if (!issueNo) {
    data = {
      gameCode: gameCode
    };
  } else {
    data = {
      issueNo: issueNo,
      gameCode: gameCode
    };
  }
  return forumRequest({
    url: "/manage/vote/get",
    method: 'post',
    data: data
  });
}
export function getIssues(_ref6) {
  var gameCode = _ref6.gameCode;
  return forumRequest({
    url: '/manage/vote/range',
    method: 'post',
    data: {
      gameCode: gameCode,
      pageSize: 20
    }
  });
}
export function getLeaderBoard(_ref7) {
  var startDate = _ref7.startDate,
    endDate = _ref7.endDate;
  return forumRequest({
    url: '/manage/statistic/getLeaderBoard',
    method: 'post',
    data: {
      startDate: startDate,
      endDate: endDate,
      pageSize: 1,
      type: 'all'
    }
  });
}
export function getChartData() {
  return forumRequest({
    url: '/manage/statistic/getChartData',
    method: 'post',
    data: {
      pageSize: 15
    }
  });
}
export function getPromotionReport(_ref8) {
  var settledDate = _ref8.settledDate;
  return systemRequest({
    url: '/api/report/promotion',
    method: 'post',
    data: {
      settledDate: settledDate
    }
  });
}
export function getStatisticData(_ref9) {
  var startDate = _ref9.startDate,
    endDate = _ref9.endDate;
  return forumRequest({
    url: '/manage/statistic/getSummaryData',
    method: 'post',
    data: {
      startDate: startDate,
      endDate: endDate,
      type: 'all'
    }
  });
}
export function getTopicContent(_ref10) {
  var category = _ref10.category,
    topicId = _ref10.topicId;
  return forumRequest({
    url: '/manage/topic/read',
    method: 'post',
    data: {
      category: category,
      topicId: topicId
    }
  });
}
export function getUnreviewedNumber() {
  return forumRequest({
    url: '/manage/statistic/getSidebarCount',
    method: 'post',
    data: {}
  });
}
export function findIssueno(_ref11) {
  var category = _ref11.category,
    _ref11$pageSize = _ref11.pageSize,
    pageSize = _ref11$pageSize === void 0 ? 10 : _ref11$pageSize;
  return forumRequest({
    url: '/manage/topic/range',
    method: 'post',
    data: {
      category: category,
      // forumId,
      pageSize: pageSize
    }
  });
}
export function issueNoList(_ref12) {
  var category = _ref12.category,
    gameCode = _ref12.gameCode,
    boardId = _ref12.boardId,
    _ref12$pageSize = _ref12.pageSize,
    pageSize = _ref12$pageSize === void 0 ? 10 : _ref12$pageSize,
    year = _ref12.year,
    colour = _ref12.colour;
  return forumRequest({
    url: '/manage/topic/range',
    method: 'post',
    data: {
      category: category,
      gameCode: gameCode,
      boardId: boardId,
      year: year,
      colour: colour
    }
  });
}

// 取得期号清单
export function getRecommendList(_ref13) {
  var pageIndex = _ref13.pageIndex,
    _ref13$pageSize = _ref13.pageSize,
    pageSize = _ref13$pageSize === void 0 ? 10 : _ref13$pageSize,
    gameCode = _ref13.gameCode;
  return forumRequest({
    url: '/manage/recommend/list',
    method: 'post',
    data: {
      pageIndex: pageIndex,
      pageSize: pageSize,
      gameCode: gameCode
    }
  });
}
// 取得期号推荐
export function getRecommendContent(_ref14) {
  var issueNo = _ref14.issueNo,
    gameCode = _ref14.gameCode;
  return forumRequest({
    url: '/manage/recommend/get',
    method: 'post',
    data: {
      issueNo: issueNo,
      gameCode: gameCode
    }
  });
}
// 更新期号推荐
export function updateRecommend(_ref15) {
  var issueNo = _ref15.issueNo,
    label = _ref15.label,
    content = _ref15.content,
    bingo = _ref15.bingo,
    gameCode = _ref15.gameCode;
  return forumRequest({
    url: '/manage/recommend/update',
    method: 'post',
    data: {
      issueNo: issueNo,
      label: label,
      content: content,
      bingo: bingo,
      gameCode: gameCode
    }
  });
}